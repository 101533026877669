/* eslint-disable require-jsdoc */
// import "typeface-nunito";
import "typeface-montserrat";
// import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
// import Seo from "../components/Seo";

// import styled from "styled-components";

// import { getScreenWidth, timeoutThrottlerHandler } from "../utils/helpers";
import Footer from "../components/Footer";
import Header from "../components/Header";

export const ThemeContext = React.createContext(null);
export const ScreenWidthContext = React.createContext(0);
export const FontLoadedContext = React.createContext(false);

import themeObjectFromYaml from "../theme/theme.yaml";
import "../custom.scss";

const Layout = (props) => {
  // const screenWidth = useWindowSize();
  const data = useStaticQuery(query);

  // const isHomePage = () => {
  //   if (props.location.pathname === "/") {
  //     return true;
  //   }

  //   return false;
  // };

  const { children } = props;
  const {
    pages: { edges: pages },
  } = data;

  return (
    <ThemeContext.Provider value={themeObjectFromYaml}>
      <div>
        {/* <Seo /> */}
        <Header
          path={props.location.pathname}
          pages={pages}
          // theme={this.state.theme}
        />
        <main style={{ minHeight: "80vh", boxSizing: "border-box" }}>{children}</main>
        <Footer />
      </div>
    </ThemeContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  // data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;

// function useWindowSize() {
//   const isClient = typeof window === "object";

//   function getSize() {
//     if (isClient) {
//       return window.innerWidth;
//     } else {
//       return 0;
//     }
//   }

//   const [windowSize, setWindowSize] = useState(getSize);

//   useEffect(() => {
//     if (!isClient) {
//       return false;
//     }

//     function handleResize() {
//       setWindowSize(getSize());
//     }

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return windowSize;
// }

const query = graphql`
query LayoutQuery {
  pages: allMdx(
    filter: { fileAbsolutePath: { regex: "//pages//" }, fields: { prefix: { regex: "/^\\d+$/" } } }
    sort: { fields: [fields___prefix], order: ASC }
  ) {
    edges {
      node {
        fields {
          slug
          prefix
        }
        frontmatter {
          title
          # menuTitle
        }
      }
    }
  }
}
`;

// const StyledDiv = styled.div`
/* @font-face {
    font-family: "'Montserrat', sans-serif";
    font-display: swap;
    src: local("Montserrat"),
      url(https://fonts.gstatic.com/s/arvo/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2)
        format("woff2");
  } */
/* html body {
    font-family: "Montserrat", sans-serif;
  } */
/* h1,
  h2,
  h3 {
    font-weight: 700;
  }
  strong {
    font-weight: 700;
  } */
/* main {
    width: auto;
    display: block;
  } */
// `;
