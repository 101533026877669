import React from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
// import theme from "../../theme/theme.yaml";
import { Link } from "gatsby";

const Footer = (props) => {
  return (
    // <StyledFragment>
    <footer className="page-footer font-small bg-gradient-light pt-4">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3">
            <h5 className="text-uppercase">The SEO Digest</h5>
            {/* <p>Here you can use rows and columns to organize your footer content.</p> */}
          </div>
          <hr className="clearfix w-100 d-md-none pb-3" />

          {/* <div className="col-md-3 mb-md-0 mb-3">

            <ul className="list-unstyled">
              <li className="mx-3 my-2">
                <Link to="/blog" key="blog" className="text-dark">
                  Blog
                </Link>
              </li>
            </ul>
          </div> */}

          <div className="col-md-3 mb-md-0 mb-3">
            {/* <h5 className="text-uppercase">Links</h5> */}

            <ul className="list-unstyled">
              <li className="mx-3 my-2">
                <Link to="/privacy" key="privacy" className="text-dark ">
                  Privacy Policy
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/terms" key="terms" className="text-dark">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">Copyright © 2020 The SEO Digest</div>
    </footer>
    // </StyledFragment>
  );
};

export default Footer;
