import { Link } from "gatsby";
import React from "react";
import Logo from "../Logo";
import config from "../../../content/meta/config";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const Header = () => (
  <Navbar bg="white" expand="md" sticky="top">
    <Navbar.Brand href="/" aria-label="theseodigest-logo">
      <Logo />
    </Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="mr-auto justify-content-end align-items-center">
      <Nav>
        {/* <Link to="/pricing" className="text-dark" style={{ verticalAlign: "middle" }}> */}
        {/* <span>Pricing</span> */}
        <Nav.Item>
          <Nav.Link
            href="http://theseodigest.plagiashield.com"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 mb-1 text-center text-primary"
          >
            <strong>Newsletter</strong>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
