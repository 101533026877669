module.exports = {
  siteTitle: "The SEO Digest - weekly SEO newsletter", // <title>
  shortSiteTitle: "The SEO Digest", // <title> ending for posts and pages
  siteDescription:
    "Receive the latest SEO news and events straight to your inbox, all curated for you.",
  siteUrl: "https://theseodigest.com",
  // pathPrefix: "",
  siteImage: "https://theseodigest.com/icons/icon.png",
  siteLanguage: "en",

  /* author */
  authorName: "Jeremy Mauboussin",
  authorTwitterAccount: "jeremy_maub",

  /* info */
  headerTitle: "Jeremy Mauboussin",
  headerSubTitle: "presents Plagia Shield",

  /* manifest.json */
  manifestName: "The SEO Digest - The SEO weekly Newsletter",
  manifestShortName: "The SEO Digest", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "#cc0a00",
  manifestThemeColor: "#cc0a00",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "twitter", url: "https://twitter.com/jeremy_maub" },
    // { name: "facebook", url: "http://facebook.com/greglobinski" }
  ],
};
